import React from "react";

export const Subscribe = (props) => {
  return (
    <div id="subscribe" className="text-center">
      <div className="container">
        <div className="section-title">
        <h2>Subscribe</h2>
           {/* add the mailer lite content here */}
          {/* data-form="eQJuMk" mailer lite AIS FrankieRiviera.com form */}
          {/* data-form="OA4USb" is mailer lite AIS AzureInnovationStation.com form */}
          <div className='emailcontainer' >
            <div class="ml-embedded" data-form="OA4USb"></div>
          </div>
        </div>
      </div>
    </div>
  );
};
