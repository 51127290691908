import React, { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { Features } from "./components/features";
// import { Foryou } from "./components/foryouold";
import { Foryou } from "./components/foryou";
import { About } from "./components/about";
// import { Apphostingguide } from "./components/apphostingguide";
import { Services } from "./components/services";
import { Subscribe } from "./components/subscribe";
import { Gallery } from "./components/gallery";
import { Videogallery } from "./components/videogallery";
import { Testimonials } from "./components/testimonials";
import { Team } from "./components/Team";
import { Contact } from "./components/contact";
import { FooterContact } from "./components/footer";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
// import "./App.css";
import { Helmet } from "react-helmet";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <div>

      <Helmet>
        <script>
          {`
    (function (w, d, e, u, f, l, n) {
      w[f] = w[f] || function () {
        (w[f].q = w[f].q || [])
        .push(arguments);
      }, l = d.createElement(e), l.async = 1, l.src = u,
      n = d.getElementsByTagName(e)[0], n.parentNode.insertBefore(l, n);
    })
      (window, document, 'script', 'https://assets.mailerlite.com/js/universal.js', 'ml');
    ml('account', '849879');
    `}
        </script>
      </Helmet>

      <Navigation />
      <Header data={landingPageData.Header} />
      <Foryou data={landingPageData.Foryou} />
      <Subscribe />
      <About data={landingPageData.About} />
      {/* <Apphostingguide data={landingPageData.Apphostingguide} /> */}
      <Videogallery data={landingPageData.Videogallery} />
      <FooterContact data={landingPageData.ContactFooter} />
      {/* <Features data={landingPageData.Features} /> */}
      {/* <Services data={landingPageData.Services} /> */}
      {/* <Gallery data={landingPageData.Gallery} /> */}
      {/* <Testimonials data={landingPageData.Testimonials} /> */}
      {/* <Team data={landingPageData.Team} /> */}
      {/* <Contact data={landingPageData.Contact} /> */}
    </div>
  );
};

export default App;
